import loadable from '@loadable/component'

const Input = loadable(() => import('./input'))
const Form = loadable(() => import('./form'))
const FormFeedback = loadable(() => import('./form-feedback'))
const PinInput = loadable(() => import('./pin-input'))

export {
	Input,
	Form,
	FormFeedback,
	PinInput,
}