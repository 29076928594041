import { useEffect, useRef, useState } from "react";
import classNames from 'classnames';

// Functions
import remToPx from '@wearetla/tla-essentials-tools/functions/rem-to-px';
import pxToRem from '@wearetla/tla-essentials-tools/functions/px-to-rem';

// Hooks
import { useBreakpoints } from "@wearetla/tla-essentials-tools/utilities/breakpoints";

const StickyCheckoutRightSection = ({ children }) => {
  const { mobile } = useBreakpoints();
  const containerRef = useRef();
  const innerContainerRef = useRef();
  const gaps = useRef({ stickTop: 23, containerLeft: 120, containerWidth: 24 });

  const [stickState, setStickState] = useState({ top: false, bottom: false });

  const calculateDimentions = () => {
    if(containerRef.current && innerContainerRef.current) {
      const bounds = containerRef.current.getBoundingClientRect();

      gaps.current = { stickTop: remToPx(2.3), containerLeft: pxToRem(bounds.left), containerWidth: pxToRem(bounds.width) };
    }
  }

  const calculateStickyness = () => {
    if(containerRef.current && innerContainerRef.current) {
      const bounds = containerRef.current.getBoundingClientRect();

      const shouldStickToTop = bounds.top <= gaps.current.stickTop;
      let shouldStickToBottom = false;

      if(shouldStickToTop) {
        if(bounds.bottom < innerContainerRef.current.offsetHeight + gaps.current.stickTop) {
          shouldStickToBottom = true;
        }
      }
      
      setStickState({ top: shouldStickToTop, bottom: shouldStickToBottom })
    }
  }

  useEffect(() => {
    if(mobile) {
      window.removeEventListener('resize', calculateDimentions);
      window.removeEventListener('resize', calculateStickyness);
      window.removeEventListener('scroll', calculateStickyness);
    }
    else {
      calculateDimentions();
      window.addEventListener('resize', calculateDimentions);
      window.addEventListener('resize', calculateStickyness);
      window.addEventListener('scroll', calculateStickyness);
    }
    
    return () => {
      window.removeEventListener('resize', calculateDimentions);
      window.removeEventListener('resize', calculateStickyness);
      window.removeEventListener('scroll', calculateStickyness);
    }
  }, [mobile])

  return (
    <aside
      className={classNames('checkout-right', { 'sticky': stickState.top, 'sticky-bottom': stickState.bottom })}
      ref={containerRef}>
      <div
        className="checkout-right-container"
        style={stickState.top ? {
          left: `${gaps.current.containerLeft}rem`,
          width: `${gaps.current.containerWidth}rem`,
        } : undefined}
        ref={innerContainerRef}>
        {children}
      </div>
    </aside>
  ) 
}

export default StickyCheckoutRightSection;